.low-cost-search-options {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  max-width: 820px;
  margin: 0 auto 60px;

  @media (min-width: 700px) {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 40px;
  }

  .best-offer-itinerary {
    justify-content: start;
  }

  .best-offer-passengers-selection {
    width: auto;
  }
}

.low-cost-search-select-flight {
  position: fixed;
  bottom: 0px;
  padding: 20px 0px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 1;
  background: white;
}

.low-cost-search-title-container {
  margin-bottom: 38px;
  margin-top: 38px;
}

.low-cost-search-title-line {
  border-bottom: 1px solid $colorBorderBackgroundShade2;
}

.low-cost-search-calendars-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  max-width: 820px;
  margin: 0 auto;
}

.low-cost-search-calendar-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.DayPicker--lowCostSearch {
  $backgroundColorDefault: #edf2f6;
  $backgroundColor: #d8e8f2;
  $backgroundColorHover: #c1dff1;
  $gapColor: #ffffff;

  width: auto !important;

  .DayPicker-Months {
    flex-wrap: wrap !important;
    gap: 30px;

    .DayPicker-Month {
      margin: 0 !important;
    }
  }

  .DayPicker-wrapper {
    margin: 0px !important;
  }

  .DayPicker-Caption {
    text-align: left;
    padding-left: 0px;
  }

  .DayPicker-Weekday {
    background-color: $backgroundColorDefault;
    border: 2px solid $gapColor;
    padding: 2px 0px;
  }

  .DayPicker-Day {
    border-radius: unset !important;
    padding: 0px;
    background-color: $backgroundColor;
    border: 2px solid $gapColor;

    &:hover {
      background-color: $backgroundColorHover !important;
    }

    &.DayPicker-Day--disabled {
      background-color: $backgroundColorDefault;
      color: unset;

      .top {
        .icon > img {
          opacity: 0.7;
        }
      }

      .bottom {
        .price {
          opacity: 0.7;
        }
      }
    }

    &.DayPicker-Day--selected {
      .price {
        color: white !important;
      }
    }

    .day-container {
      height: 50px;
      width: 50px;
      display: flex;
      justify-content: space-between;
      flex-flow: column;
      font-size: 10px;
      padding: 2px;

      .top {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .day {
          text-align: left;
        }

        .icon > img {
          height: 13px;
          margin-top: 2px;
          margin-right: 2px;
        }
      }

      .bottom {
        flex: 1;
        display: flex;
        align-items: flex-end;

        .price {
          flex: 1;
          text-align: right;
          font-weight: bold;
          color: #408fdf;
        }
      }
    }
  }
}

.select-low-cost-flights-modal {
  padding: 32px 30px !important;
  height: fit-content;
  width: 80vw;
  @media (min-width: $desktop) {
    width: 650px;
  }

  .select-low-cost-flights-modal__title {
    font-size: 28px;
    font-weight: bold;
    margin: 0 0 20px;
  }

  .select-low-cost-flights-modal__text {
    margin-bottom: 20px;
  }

  .select-low-cost-flights-modal__buttons {
    text-align: right;
  }
}
